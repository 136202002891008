import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";
import { colors } from "../../utils/theme";

const FullWidthImageBanner = ({ backgroundImages, defaultBgImage }) => {
  const headerRef = useRef(null);

  useEffect(() => {
    let i = 0;
    const interval = setInterval(function () {
      const element = headerRef.current;
      if (!!element) {
        switch (i) {
          case 0:
            element.style.backgroundImage = `url("${backgroundImages[0]}")`;
            break;
          case 1:
            element.style.backgroundImage = `url("${backgroundImages[1]}")`;
            break;
          case 2:
            element.style.backgroundImage = `url("${backgroundImages[2]}")`;
            break;
          case 3:
            element.style.backgroundImage = `url("${backgroundImages[3]}")`;
            break;
          case 4:
            element.style.backgroundImage = `url("${backgroundImages[4]}")`;
            break;
          case 5:
            element.style.backgroundImage = `url("${backgroundImages[5]}")`;
            break;
          default:
            element.style.backgroundImage = `url("${defaultBgImage.default_bg_image}")`;
        }
        i++;
        if (i > 4) i = 0;
      }
    }, 5000);
    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(interval);
  }, [backgroundImages, defaultBgImage]);

  return (
    <div className="section">
      <div style={{ position: "relative" }}>
        <ButterflyStyleElement
          src={"/assets/images/illustrations/illustratie-07.svg"}
        />
        <Wrapper className="position-relative">
          <div className="image-container" ref={headerRef}></div>
        </Wrapper>
      </div>
    </div>
  );
};

const ButterflyStyleElement = styled.img`
  position: absolute;
  width: 180px;
  right: 55vw;
  bottom: 60vh;
  z-index: 0;

  @media ${device.maxXl} {
    bottom: -20vh;
  }

  @media ${device.maxLg} {
    display: none;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  min-height: 500px;

  .image-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    box-shadow: 0 20px 40px 4px rgba(0, 0, 0, 0.2);
    transition: background 1.5s ease;

    background-image: url("/assets/images/crew/ow-crew-1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  background-color: ${colors.light};

  @media ${device.maxLg} {
  }
`;

export default FullWidthImageBanner;
