import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { clone, filter } from "lodash";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Upload,
} from "antd";
import locale from "antd/es/date-picker/locale/nl_NL";
import { getDisabledDatesForEndDateEvent } from "../../functions/Datepicker";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { STORAGE_EVENTS_LOCATION } from "../../constants/StorageConstants";
import { FIRESTORE_EVENTS_TABLE } from "../../constants/FirebaseConstants";
import { beforeImageUpload, dummyRequest } from "../../functions/Images";
import {
  DashboardCard,
  DashboardSection,
  DashboardWrapper,
  StyleElementContainer,
} from "../../styles/dashboardStyles";
import styled from "styled-components";
import { colors, styles } from "../../utils/theme";
import StyleElement from "../../components/StyleElement";
import { device } from "../../utils/breakpoints";
import useIsMobile from "../../components/Hooks/useIsMobile";
import ReactMarkdown from "react-markdown";
import { userProfileRaw } from "../../urls";
import { Link } from "react-router-dom";

const { TextArea } = Input;

const Events = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const allEvents = useSelector(
    (state) => state.firestoreReducer.ordered.events,
  );
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const users = useSelector((state) => state.firestoreReducer.ordered.users);
  const [createEventForm] = Form.useForm();
  const [editEventForm] = Form.useForm();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const storage = firebase.storage();
  const [events, setEvents] = useState([]);
  const [createEventModalVisible, setCreateEventModalVisible] = useState(false);
  const [showEventModalVisible, setShowEventModalVisible] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [editing, setEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const nextEvents = filter(allEvents, (item) => {
      return (
        dayjs.unix(item.endDate).isSameOrAfter(dayjs()) && item.type === "event"
      );
    });
    setEvents(nextEvents);
  }, [allEvents]);

  const handleCreateEvent = () => {
    setIsSubmitting(true);

    createEventForm
      .validateFields()
      .then((values) => {
        const object = {
          type: "event",
          title: values.title,
          startDate: dayjs(values.startDate).unix(),
          endDate: dayjs(values.endDate).unix(),
          description: values.description || null,
          location: values.location,
          private: values.private || false,
          createdBy: auth.uid,
          createdAt: dayjs().unix(),
        };

        delete values.image;

        if (!!uploadedImage) {
          const fileName = values.title + "-" + dayjs(values.startDate).unix();
          const storageRef = storage.ref(
            STORAGE_EVENTS_LOCATION + "/" + fileName,
          );

          storageRef
            .put(uploadedImage.originFileObj)
            .then(() => storageRef.getDownloadURL())
            .then((downloadUrl) => {
              saveEventToFirestore(object, downloadUrl);
              setIsSubmitting(false);
            })
            .catch((error) => {
              setIsSubmitting(false);
            });
        } else {
          saveEventToFirestore(object, null);
          setIsSubmitting(false);
        }
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const saveEventToFirestore = (event, downloadUrl) => {
    firestore
      .collection(FIRESTORE_EVENTS_TABLE)
      .add({ ...event, thumbnail: !!downloadUrl ? downloadUrl : null })
      .then(() => closeCreateEventModal())
      .catch((err) => console.log(err));
  };

  const openCreateEventModal = () => {
    setCreateEventModalVisible(true);
  };

  const closeCreateEventModal = () => {
    setCreateEventModalVisible(false);
    setUploadedImage(null);
    setIsPrivate(false);
    setStartDate(null);
    createEventForm.resetFields();
  };

  const openEditModal = () => {
    editEventForm.setFieldsValue({
      type: currentEvent.type,
      title: currentEvent.title,
      eventDate: [dayjs.unix(currentEvent.start), dayjs.unix(currentEvent.end)],
      startDate: dayjs.unix(currentEvent.startDate),
      endDate: dayjs.unix(currentEvent.endDate),
      location: currentEvent.location,
      description: currentEvent.description || null,
    });
    setIsPrivate(currentEvent.private);
    setEditing(true);
  };

  const handleEditEvent = () => {
    editEventForm.validateFields().then((values) => {
      const object = {
        type: values.type,
        title: values.title,
        startDate: dayjs(values.startDate).unix(),
        endDate: dayjs(values.endDate).unix(),
        description: values.description || null,
        location: values.location,
        private: values.private || isPrivate,
        updatedAt: dayjs().unix(),
      };
      delete values.image;
      if (!!uploadedImage) {
        const fileName = values.title + "-" + dayjs(startDate).unix();
        const storageRef = storage.ref(
          STORAGE_EVENTS_LOCATION + "/" + fileName,
        );
        storageRef.put(uploadedImage.originFileObj).then((snapshot) => {
          storageRef.getDownloadURL().then((downloadUrl) => {
            updateEventToFirestore(object, downloadUrl);
          });
        });
      } else {
        updateEventToFirestore(object);
      }
    });
  };

  const updateEventToFirestore = (event, downloadUrl) => {
    firestore
      .collection(FIRESTORE_EVENTS_TABLE)
      .doc(currentEvent.id)
      .update({ ...event, thumbnail: downloadUrl || currentEvent.thumbnail })
      .then(() => {
        closeEditModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeEditModal = () => {
    setShowEventModalVisible(false);
    setEditing(false);
    setCurrentEvent(null);
    setUploadedImage(null);
    editEventForm.resetFields();
  };

  const handleDeleteEvent = () => {
    firestore
      .collection(FIRESTORE_EVENTS_TABLE)
      .doc(currentEvent.id)
      .delete()
      .then(() => {
        closeEditModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickItem = (e, event) => {
    e.stopPropagation();
    let clonedEvent = clone(event);
    setCurrentEvent(clonedEvent);
    setShowEventModalVisible(true);
  };

  const imageUploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    onChange(info) {
      if (info.file.status === "done") {
        setUploadedImage(info.file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === "removed") setUploadedImage(null);
    },
  };

  return (
    <DashboardLayout>
      <DashboardWrapper>
        <DashboardSection>
          <StyledButton type="primary" onClick={openCreateEventModal}>
            {t("create_event")}
          </StyledButton>
          <EventsGrid>
            {events.map((event, index) => {
              const startAndEndDateAreEqual = dayjs
                .unix(event.startDate)
                .isSame(dayjs.unix(event.endDate), "day");
              const startDateIsToday =
                dayjs.unix(event.startDate).isSame(dayjs(), "day") &&
                dayjs.unix(event.startDate).isSame(dayjs(), "year");
              const startDateIsThisWeek =
                dayjs.unix(event.startDate).isSame(dayjs(), "week") &&
                dayjs.unix(event.startDate).isSame(dayjs(), "year");
              return (
                <StyledDashboardCard
                  key={index}
                  onClick={(e) => handleClickItem(e, event)}
                >
                  <div className="card-image">
                    <img
                      src={
                        !!event.thumbnail
                          ? event.thumbnail
                          : "/assets/images/event/placeholder.png"
                      }
                      alt={event.title}
                      className="w-100"
                    />
                  </div>
                  <CardBody>
                    <DateTile
                      isToday={startDateIsToday}
                      isThisWeek={startDateIsThisWeek}
                    >
                      <h4 className="fw-700 font-md ls-3  mb-0">
                        <span className="ls-3 d-block font-xsss text-white fw-500">
                          {dayjs.unix(event.startDate).format("DD")}
                        </span>
                        <span className={"text-uppercase"}>
                          {dayjs.unix(event.startDate).format("MMM")}
                        </span>
                        <span className="ls-3 d-block font-xsss text-white fw-500">
                          {dayjs.unix(event.startDate).format("YYYY")}
                        </span>
                      </h4>
                    </DateTile>
                    <EventContent>
                      <h2 className="fw-700 lh-3 font-xss">{event.title} </h2>
                      <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                        <i className="ti-location-pin me-1"></i>
                        {event.location}
                      </span>
                      <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                        <i className="ti-time me-1"></i>
                        {dayjs.unix(event.startDate).format("HH:mm")}
                        {` ${t("until").toLowerCase()} `}
                        {startAndEndDateAreEqual
                          ? dayjs.unix(event.endDate).format("HH:mm")
                          : dayjs.unix(event.endDate).format("DD MMM HH:mm")}
                      </span>
                    </EventContent>
                  </CardBody>
                </StyledDashboardCard>
              );
            })}
          </EventsGrid>
        </DashboardSection>
      </DashboardWrapper>
      <StyleElementContainer>
        <StyleElement
          src={"/assets/images/illustrations/illustratie-14.svg"}
          alt={""}
          bottom={-20}
          right={-13}
        />
      </StyleElementContainer>
      {/* Create event modal */}
      <Modal
        title={t("create_event")}
        open={createEventModalVisible}
        onCancel={closeCreateEventModal}
        onOk={handleCreateEvent}
        okText={t("save")}
        cancelText={t("close")}
        okButtonProps={{ loading: isSubmitting }}
      >
        <Form layout="vertical" form={createEventForm}>
          <Row gutter={16}>
            <Col lg={16}>
              <Form.Item
                name="title"
                label={t("event_title")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_event_title"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item name="image" label={t("event_image")}>
                <ImgCrop
                  modalWidth={1000}
                  minZoom={0}
                  modalTitle={t("crop_image")}
                  modalOk={t("add")}
                  modalCancel={t("close")}
                  aspect={1}
                  cropperProps={{
                    restrictPosition: false,
                  }}
                >
                  <Upload
                    {...imageUploadProps}
                    customRequest={dummyRequest}
                    beforeUpload={beforeImageUpload}
                  >
                    <Button icon={<UploadOutlined />}>{t("upload")}</Button>
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={16}>
              <Form.Item
                name="location"
                label={t("location")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_event_location"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                name="private"
                label={t("private")}
                valuePropName="checked"
              >
                <Switch
                  checked={isPrivate}
                  onChange={setIsPrivate}
                  checkedChildren={t("yes")}
                  unCheckedChildren={t("no")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={12}>
              <Form.Item
                name="startDate"
                label={t("start_date")}
                rules={[
                  {
                    required: true,
                    message: t("form.choose_start_date"),
                  },
                ]}
              >
                <DatePicker
                  className={"w-100"}
                  locale={locale}
                  format={"DD-MM-YYYY HH:mm"}
                  placeholder={t("event_start_placeholder")}
                  onChange={(date) => {
                    setStartDate(date);
                    const suggestedEndDate = dayjs(date + 1000 * 60 * 60 * 2);
                    createEventForm.setFieldsValue({
                      endDate: suggestedEndDate,
                    });
                  }}
                  disabledDate={(current) => {
                    return current < dayjs().startOf("day");
                  }}
                  showToday={false}
                  minuteStep={5}
                  inputReadOnly={!isMobile}
                  showTime={!isMobile}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                name="endDate"
                label={t("end_date")}
                rules={[
                  {
                    required: true,
                    message: t("form.choose_end_date"),
                  },
                ]}
              >
                <DatePicker
                  className={"w-100"}
                  locale={locale}
                  format={"DD-MM-YYYY HH:mm"}
                  placeholder={t("event_end_placeholder")}
                  disabledDate={(current) => {
                    return getDisabledDatesForEndDateEvent(startDate, current);
                  }}
                  showToday={false}
                  minuteStep={5}
                  inputReadOnly={!isMobile}
                  showTime={!isMobile}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={24}>
              <Form.Item
                name="description"
                label={t("description")}
                rules={[
                  {
                    message: t("form.enter_event_description"),
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/*Edit event modal*/}
      {!!currentEvent && (
        <StyledModal
          title={editing ? t("edit_activity") : currentEvent.title}
          open={showEventModalVisible}
          onCancel={closeEditModal}
          onOk={() => (editing ? handleEditEvent() : openEditModal())}
          okText={t(editing ? "save" : "edit_event")}
          cancelText={t("close")}
          footer={[
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: editing ? "end" : "end",
                width: "100%",
                gap: 8,
              }}
            >
              {editing && (
                <Popconfirm
                  placement="bottom"
                  title={t("confirm_delete_activity_title")}
                  description={t("confirm_delete_event_text")}
                  onConfirm={handleDeleteEvent}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button danger style={{ marginRight: "auto" }}>
                    {t("delete")}
                  </Button>
                </Popconfirm>
              )}
              <div
                style={{
                  display: "flex",
                  gap: 8,
                }}
              >
                <Button onClick={closeEditModal}>{t("close")}</Button>
                <Button
                  key="submit"
                  type="primary"
                  style={{ margin: 0 }}
                  onClick={() =>
                    editing ? handleEditEvent() : openEditModal()
                  }
                >
                  {t(editing ? "save" : "edit_event")}
                </Button>
              </div>
            </div>,
          ]}
        >
          {!!currentEvent &&
            (editing ? (
              <Form layout="vertical" form={editEventForm}>
                <Row gutter={16}>
                  <Col lg={16}>
                    <Form.Item
                      name="type"
                      label={t("activity_type")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_event_type"),
                        },
                      ]}
                    >
                      <Select placeholder={t("form.select_event_type")}>
                        <Select.Option value="meeting">
                          {t("meeting")}
                        </Select.Option>
                        <Select.Option value="event">
                          {t("event")}
                        </Select.Option>
                        <Select.Option value="announcement">
                          {t("announcement")}
                        </Select.Option>
                        <Select.Option value="personally">
                          {t("personally")}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col lg={16}>
                    <Form.Item
                      name="title"
                      label={t("activity_title")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_event_title"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={8}>
                    <Form.Item name="image" label={t("event_image")}>
                      <ImgCrop
                        modalWidth={1000}
                        minZoom={0}
                        modalTitle={t("crop_image")}
                        modalOk={t("add")}
                        modalCancel={t("close")}
                        aspect={1}
                        cropperProps={{
                          restrictPosition: false,
                        }}
                      >
                        <Upload
                          {...imageUploadProps}
                          customRequest={dummyRequest}
                          beforeUpload={beforeImageUpload}
                        >
                          <Button icon={<UploadOutlined />}>
                            {t("upload")}
                          </Button>
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col lg={16}>
                    <Form.Item
                      name="location"
                      label={t("location")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_event_location"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={8}>
                    <Form.Item name="private" label={t("private")}>
                      <Switch
                        checked={isPrivate}
                        onChange={setIsPrivate}
                        checkedChildren={t("yes")}
                        unCheckedChildren={t("no")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col lg={12}>
                    <Form.Item
                      name="startDate"
                      label={t("start_date")}
                      rules={[
                        {
                          required: true,
                          message: t("form.choose_start_date"),
                        },
                      ]}
                    >
                      <DatePicker
                        className={"w-100"}
                        locale={locale}
                        format={"DD-MM-YYYY HH:mm"}
                        placeholder={t("event_start_placeholder")}
                        onChange={(date) => {
                          setStartDate(date);
                          const suggestedEndDate = dayjs(
                            date + 1000 * 60 * 60 * 2,
                          );
                          editEventForm.setFieldsValue({
                            endDate: suggestedEndDate,
                          });
                        }}
                        disabledDate={(current) => {
                          return current < dayjs().startOf("day");
                        }}
                        showToday={false}
                        minuteStep={5}
                        inputReadOnly={!isMobile}
                        showTime={!isMobile}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      name="endDate"
                      label={t("end_date")}
                      rules={[
                        {
                          required: true,
                          message: t("form.choose_end_date"),
                        },
                      ]}
                    >
                      <DatePicker
                        className={"w-100"}
                        locale={locale}
                        format={"DD-MM-YYYY HH:mm"}
                        placeholder={t("event_end_placeholder")}
                        disabledDate={(current) => {
                          return getDisabledDatesForEndDateEvent(
                            startDate,
                            current,
                          );
                        }}
                        showToday={false}
                        minuteStep={5}
                        inputReadOnly={!isMobile}
                        showTime={!isMobile}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="description"
                      label={t("description")}
                      rules={[
                        {
                          message: t("form.enter_event_description"),
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            ) : (
              <>
                <Row>
                  <Col>
                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                      <i className="ti-calendar me-1"></i>
                      {t(currentEvent.type)}
                    </span>
                    {!!currentEvent.createdBy && (
                      <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                        <i className="ti-user me-1"></i>
                        {t("created_by")}{" "}
                        <Link
                          to={userProfileRaw + currentEvent.createdBy}
                          style={{
                            display: "contents",
                            color: colors.primaryLight,
                          }}
                        >
                          {
                            users.find(
                              (user) => user.id === currentEvent.createdBy,
                            ).firstName
                          }{" "}
                          {
                            users.find(
                              (user) => user.id === currentEvent.createdBy,
                            ).lastName
                          }
                        </Link>
                      </span>
                    )}
                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                      <i className="ti-time me-1"></i>
                      {dayjs
                        .unix(currentEvent.startDate)
                        .format("DD MMM HH:mm")}
                      {` ${t("until").toLowerCase()} `}
                      {dayjs
                        .unix(currentEvent.startDate)
                        .isSame(dayjs.unix(currentEvent.endDate), "day")
                        ? dayjs.unix(currentEvent.endDate).format("HH:mm")
                        : dayjs
                            .unix(currentEvent.endDate)
                            .format("DD MMM HH:mm")}
                    </span>
                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                      <i className="ti-location-pin me-1"></i>
                      {currentEvent.location}
                    </span>
                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                      {currentEvent.private ? (
                        <>
                          <i className="ti-lock me-1"></i>
                          {t("private")}
                        </>
                      ) : (
                        <>
                          <i className="ti-unlock me-1"></i>
                          {t("public")}
                        </>
                      )}
                    </span>
                  </Col>
                </Row>
                <Row>
                  {!!currentEvent.description && (
                    <Col lg={24}>
                      <ReactMarkdown>{currentEvent.description}</ReactMarkdown>
                    </Col>
                  )}
                  {!!currentEvent.thumbnail && (
                    <Col lg={24} style={{ marginInline: "auto" }}>
                      <img
                        src={currentEvent.thumbnail}
                        alt={currentEvent.title}
                        className="w-100"
                      />
                    </Col>
                  )}
                </Row>
              </>
            ))}
        </StyledModal>
      )}
    </DashboardLayout>
  );
};

const EventsGrid = styled.div`
  z-index: 1;
  display: grid;
  gap: 1.5rem;
  margin-bottom: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

  @media only screen and ${device.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and ${device.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledDashboardCard = styled(DashboardCard)`
  gap: 1rem;
  cursor: pointer;

  .card-image {
    overflow: hidden;
    width: 100%;
    border-radius: ${styles.card_border_radius};
  }

  img {
    transition: transform 0.3s ease-out;
    user-drag: none;
    -webkit-user-drag: none;
  }

  &:hover img {
    transform: scale(105%);
  }
`;

const CardBody = styled.div`
  display: flex;
  gap: 1rem;
`;

const DateTile = styled.div`
  height: fit-content;
  width: fit-content;
  padding: 0.5rem;
  background-color: ${({ isToday, isThisWeek }) =>
    isToday
      ? colors.secondary
      : isThisWeek
        ? colors.secondaryLight
        : colors.primaryLight};
  border-radius: ${styles.btn_border_radius};

  h4:last-child {
    color: ${({ isToday }) => (isToday ? "white" : "initial")};
  }
`;

const EventContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledModal = styled(Modal)`
  .ant-modal-title {
    padding-right: 1rem;
  }

  .ant-modal-body {
    display: grid;
    gap: 1rem;
  }
`;

const StyledButton = styled(Button)`
  @media ${device.md} {
    align-self: flex-end;
  }
`;

export default Events;
