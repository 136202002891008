import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { filter } from "lodash";
import {
  DashboardSection,
  DashboardWrapper,
  StyleElementContainer,
} from "../styles/dashboardStyles";
import styled from "styled-components";
import StyleElement from "../components/StyleElement";
import { device } from "../utils/breakpoints";
import HomeLayout from "../layouts/HomeLayout";
import { HeaderSubtitle, HeaderTitle } from "../styles/sharedStyles";
import EventCard from "../components/EventCard";

const PublicEvents = () => {
  const { t } = useTranslation();
  const allEvents = useSelector(
    (state) => state.firestoreReducer.ordered.events
  );

  const [events, setEvents] = useState([]);
  const [expandedCardId, setExpandedCardId] = useState(null);

  useEffect(() => {
    const nextEvents = filter(allEvents, (item) => {
      return (
        dayjs.unix(item.endDate).isSameOrAfter(dayjs()) && item.type === "event"
      );
    });
    setEvents(nextEvents);
  }, [allEvents]);

  const handleCardExpand = (eventId) => {
    setExpandedCardId(expandedCardId === eventId ? null : eventId);
  };

  return (
    <HomeLayout>
      <DashboardWrapper>
        <DashboardSection>
          <HeaderWrapper>
            <HeaderTitle>{t("upcoming_events")}</HeaderTitle>
            <HeaderSubtitle>{t("check_the_schedule")}</HeaderSubtitle>
          </HeaderWrapper>
          <EventsGrid>
            {events
              .filter((event) => event.private === false)
              .map((event) => (
                <EventCard
                  key={event.id}
                  event={event}
                  isExpanded={expandedCardId === event.id}
                  onExpand={() => handleCardExpand(event.id)}
                />
              ))}
          </EventsGrid>
        </DashboardSection>
      </DashboardWrapper>
      <StyleElementContainer>
        <StyleElement
          src={"/assets/images/illustrations/illustratie-14.svg"}
          alt={""}
          bottom={-20}
          right={-13}
        />
      </StyleElementContainer>
    </HomeLayout>
  );
};

const HeaderWrapper = styled.div`
  margin-bottom: 2rem;
`;

const EventsGrid = styled.div`
  z-index: 1;
  display: grid;
  gap: 1.5rem;
  margin-bottom: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

  @media only screen and ${device.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and ${device.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default PublicEvents;
