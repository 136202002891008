import React, { useEffect, useRef, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import HomeLayout from "../layouts/HomeLayout";
import { device } from "../utils/breakpoints";
import { colors } from "../utils/theme";
import StyledButton from "../components/StyledButton";
import { toggleHireSpaceModal } from "../redux/actions/modalActions";
import { useDispatch } from "react-redux";
import StyleElement from "../components/StyleElement";
import LinkButton from "../components/LinkButton";
import { contact } from "../urls";
import CtaBanner from "../components/Banners/CtaBanner";
import HireSpaceModal from "../components/Modal/HireSpaceModal";
import ReactMarkdown from "react-markdown";
import FullWidthImageBanner from "../components/Banners/FullWidthImageBanner";

const ProductDetail = () => {
  const { productId } = useParams();
  const { t } = useTranslation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const headerRef = useRef(null);

  const productToSpaceId = {
    flexwerken: "FLEX_SPACE", // Replace with actual IDs from your data
    "vaste-unit": "STUDIO_SPACE",
    "multifunctionele-ruimte": "MEETING_SPACE",
  };

  useEffect(() => {
    const loadProduct = async () => {
      try {
        const productData = await import(`../data/products/${productId}.json`);
        setProduct(productData.default);
      } catch (error) {
        console.error("Error loading product:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    loadProduct();
  }, [productId]);

  useEffect(() => {
    let i = 0;
    const interval = setInterval(function () {
      const element = headerRef.current;
      if (!!element) {
        switch (i) {
          case 0:
            element.style.backgroundImage = `url("${product.images[0]}")`;
            break;
          case 1:
            element.style.backgroundImage = `url("${product.images[1]}")`;
            break;
          case 2:
            element.style.backgroundImage = `url("${product.images[2]}")`;
            break;
          case 3:
            element.style.backgroundImage = `url("${product.images[3]}")`;
            break;
          case 4:
            element.style.backgroundImage = `url("${product.images[4]}")`;
            break;
          case 5:
            element.style.backgroundImage = `url("${product.images[5]}")`;
            break;
          default:
            element.style.backgroundImage = `url("${product.default_image}")`;
        }
        i++;
        if (i > 4) i = 0;
      }
    }, 5000);

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(interval);
  }, [product]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Navigate to="/" replace />;
  }

  if (!product) {
    return <Navigate to="/" replace />;
  }

  return (
    <HomeLayout>
      <div className={"position-relative"}>
        <StyleElement
          src={"/assets/images/illustrations/illustratie-18.svg"}
          alt={""}
          right={-18}
          bottom={-110}
        />
      </div>
      <HeaderWrapper>
        <div className="banner-image">
          <div className="image-container" ref={headerRef} />
        </div>
        <div className="banner-content">
          <div className="content-container">
            <HeaderTitle>{product.title}</HeaderTitle>
            <HeaderSubtitle>
              <ReactMarkdown>{product.subtitle}</ReactMarkdown>
            </HeaderSubtitle>
            <Price>
              <p className="price">{product.price}</p>
              <p className="price-note">{product.price_note}</p>
            </Price>

            <div className={"d-flex mt-3"}>
              <StyledButton onClick={() => dispatch(toggleHireSpaceModal())}>
                {t("rent_a_space")}
              </StyledButton>
              <LinkButton to={contact} small type={"outlined"}>
                {t("get_in_touch")}
              </LinkButton>
            </div>
          </div>
        </div>
      </HeaderWrapper>

      <FullWidthImageBanner
        backgroundImages={product.bg_images}
        defaultBgImage={product.default_bg_image}
      />

      <CtaBanner light />
      <HireSpaceModal preselectedSpaceId={productToSpaceId[productId]} />
    </HomeLayout>
  );
};

const Price = styled.div`
  margin-bottom: 26px;

  p {
    margin: 0;

    &.price {
      margin-top: 20px;
      font-size: 22px;
      color: ${colors.secondary};
      font-weight: 600;
    }

    &.price-note {
      margin-top: 2px;
      font-size: 12px;
    }
  }
`;

const HeaderWrapper = styled.div`
  min-height: 100vh;
  display: grid;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 10vh;
  z-index: 9;
  isolation: isolate;

  .banner-content {
    grid-column: 1;
    display: grid;
    place-content: center;

    .content-container {
      max-width: 600px;
      padding-inline: 2rem;
    }
  }

  .banner-image {
    display: flex;
    align-items: center;
    min-height: 35vh;
  }

  .image-container {
    display: grid;
    place-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    box-shadow: 0 20px 40px 4px rgba(0, 0, 0, 0.2);
    transition: background 1.5s ease;

    background-image: url("/assets/images/crew/ow-crew-1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media ${device.lg} {
    padding-top: 0;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 20vh;

    .banner-content {
      grid-column: 2;
    }

    .banner-image {
      padding-right: 4rem;
      grid-column: 1;
      grid-row: 1;
    }

    .image-container {
      height: 80%;
      border-radius: 0 6px 6px 0;
      box-shadow: 0 0 40px 8px rgba(0, 0, 0, 0.2);
    }

    .content-container {
      margin-right: 10vw;
    }
  }
`;

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${colors.header};
  @media ${device.lg} {
    font-size: 38px;
  }
`;

const HeaderSubtitle = styled.h4`
  font-size: 22px;
  font-weight: 300;

  /* Style markdown content within the subtitle */
  p {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  /* Ensure other markdown elements inherit styles */
  strong,
  em,
  a {
    font-size: inherit;
  }

  @media ${device.lg} {
    font-size: 24px;
  }
`;

export default ProductDetail;
