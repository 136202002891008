import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LinkButton from "../LinkButton";
import { colors } from "../../utils/theme";
import { device } from "../../utils/breakpoints";
import { contact } from "../../urls";

const CtaBanner = ({ light }) => {
  const { t } = useTranslation();

  return (
    <div className="section">
      <div className="container text-center d-flex justify-content-center">
        <div className={"col-lg-9"}>
          <BannerImage
            src="/assets/images/illustrations/illustratie-19.svg"
            alt="Oostwest"
            className="img-fluid"
          />
          <BannerTitle light={light ? 1 : 0}>{t("home_cta_title")}</BannerTitle>
          <BannerText light={light ? 1 : 0}>{t("home_cta_desc")}</BannerText>
          <LinkButton to={contact}>{t("home_cta_btn")}</LinkButton>
        </div>
      </div>
    </div>
  );
};

const BannerTitle = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ light }) => (light === 1 ? colors.light : colors.header)};
  margin-bottom: 0.25rem;
  margin-top: 0;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 10%);

  @media ${device.lg} {
    font-size: 28px;
    line-height: 36px;
  }
`;

const BannerText = styled.p`
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: ${({ light }) => (light === 1 ? colors.light : colors.text)};
  margin-bottom: 1.2rem;
  margin-top: 0;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 10%);

  @media ${device.lg} {
    font-size: 25px;
    line-height: 38px;
  }
`;

const BannerImage = styled.img`
  max-width: 150px;
  shadow: 0 2px 10px rgba(0, 0, 0, 10%);
`;

export default CtaBanner;
