import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../Logo";
import { about, contact, dashboardHome, home, publicEvents } from "../../urls";
import { useDispatch } from "react-redux";
import { toggleHireSpaceModal } from "../../redux/actions/modalActions";
import StyledButton from "../StyledButton";
import MobileMenuHome from "../MobileMenuHome";
import {
  DesktopHeader,
  HamburgerMenu,
  MobileHeader,
  MobileItemsWrapper,
  NavItem,
} from "../../styles/sharedStyles";
import DropdownMenu from "./DropdownMenu";

const HomeNavbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.classList.toggle("fixed_body");
  };

  const closeMenu = () => {
    setMenuOpen(false);
    document.body.classList.remove("fixed_body");
  };

  return (
    <>
      <DesktopHeader>
        <div className="container max-container">
          <div className="row">
            <div className={"col-lg-1 col-6"}>
              <Logo header />
            </div>
            <div className="col-lg-9 col-md-6 col-sm-6 d-none d-lg-block align-self-center">
              <ul className="list-inline text-center">
                <NavItem className="list-inline-item pe-4 ps-4">
                  <Link to={home}>{t("oostwest")}</Link>
                </NavItem>
                <NavItem className="list-inline-item pe-4 ps-4">
                  <Link to={about}>{t("community")}</Link>
                </NavItem>
                <DropdownMenu />
                <NavItem className="list-inline-item pe-4 ps-4">
                  <Link to={publicEvents}>{t("events")}</Link>
                </NavItem>
                <NavItem className="list-inline-item pe-4 ps-4">
                  <Link to={contact}>{t("contact")}</Link>
                </NavItem>
              </ul>
            </div>
            <div className="col-lg-2 col-6 text-right align-self-center d-flex align-items-end">
              <NavItem className="list-inline-item">
                <Link to={dashboardHome} style={{ marginRight: 12 }}>
                  {t("login")}
                </Link>
              </NavItem>
              <StyledButton onClick={() => dispatch(toggleHireSpaceModal())}>
                {t("rent_a_space")}
              </StyledButton>
            </div>
          </div>
        </div>
      </DesktopHeader>
      <MobileHeader>
        <MobileItemsWrapper>
          <HamburgerMenu
            onClick={() => handleMenu()}
            className={menuOpen ? "active" : ""}
          >
            <span />
            <span />
            <span />
          </HamburgerMenu>
          <Logo header />
          <div />
        </MobileItemsWrapper>
      </MobileHeader>
      <MobileMenuHome active={menuOpen} handleMenu={closeMenu} />
    </>
  );
};

export default HomeNavbar;
