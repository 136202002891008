import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";
import {
  events,
  adminOverview,
  dashboardHome,
  settings,
  usersOverview,
  agenda,
} from "../urls";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { device } from "../utils/breakpoints";
import { DesktopHeader } from "../styles/sharedStyles";
import StyledButton from "./StyledButton";
import { toggleHireSpaceModal } from "../redux/actions/modalActions";
import { colors } from "../utils/theme";

const Header = () => {
  const { t } = useTranslation();
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isNoti, setIsNoti] = useState(false);
  const isAdmin = !!profile && profile.role === "admin";

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const toggleNoti = () => {
    setIsNoti(!isNoti);
  };

  const navClass = `${isOpen ? " nav-active" : ""}`;
  const buttonClass = `${isOpen ? " active" : ""}`;
  const searchClass = `${isActive ? " show" : ""}`;
  const notiClass = `${isNoti ? " show" : ""}`;

  return (
    <DesktopHeader>
      <div className="container max-container">
        <MenuContainer>
          <div>
            <Logo header to={dashboardHome} />
          </div>
          <StyledNav>
            <StyledNavLink activeClassName="active" to={dashboardHome} end>
              <i className="feather-home font-lg btn-round-lg" />
            </StyledNavLink>
            <StyledNavLink activeClassName="active" to={agenda}>
              <i className="feather-calendar font-lg btn-round-lg" />
            </StyledNavLink>
            <StyledNavLink activeClassName="active" to={events}>
              <i className="feather-star font-lg btn-round-lg" />
            </StyledNavLink>
            <StyledNavLink activeClassName="active" to={usersOverview}>
              <i className="feather-users font-lg btn-round-lg " />
            </StyledNavLink>
            {isAdmin && (
              <StyledNavLink activeClassName="active" to={adminOverview}>
                <i className="feather-user-plus font-lg btn-round-lg " />
              </StyledNavLink>
            )}
          </StyledNav>
          <AvatarContainer>
            <Link to={settings}>
              <Avatar
                src={
                  !!profile.avatar
                    ? profile.avatar
                    : "https://placehold.co/300x300"
                }
                alt="user"
              />
            </Link>
          </AvatarContainer>
          <div className={`app-header-search ${searchClass}`}>
            <form className="search-form">
              <div className="form-group searchbox mb-0 border-0 p-1">
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="Search..."
                />
                <i className="input-icon">
                  <ion-icon
                    name="search-outline"
                    role="img"
                    className="md hydrated"
                    aria-label="search outline"
                  />
                </i>
                <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                  <i className="ti-close font-xs" onClick={toggleActive} />
                </span>
              </div>
            </form>
          </div>
        </MenuContainer>
      </div>
    </DesktopHeader>
  );
};

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const StyledNav = styled.nav`
  margin-right: auto;
  display: flex;
  gap: 1rem;
`;

const AvatarContainer = styled.div``;

const StyledNavLink = styled(NavLink)`
  & i {
    color: ${colors.primaryLight};
    background-color: white;
    transition: 0.3s ease-out;
  }

  &:hover i {
    color: ${colors.secondaryLight};
  }

  &.active i {
    color: white;
    background-color: ${colors.secondary};
  }
`;

const Avatar = styled.img`
  width: 50px;
  border-radius: 100%;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
`;

export default Header;
