import React from "react";
import styled from "styled-components";
import { styles, colors } from "../utils/theme";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const EventCard = ({ event, isExpanded, onExpand }) => {
  const { t } = useTranslation();

  const startAndEndDateAreEqual = dayjs
    .unix(event.startDate)
    .isSame(dayjs.unix(event.endDate), "day");

  const startDateIsToday =
    dayjs.unix(event.startDate).isSame(dayjs(), "day") &&
    dayjs.unix(event.startDate).isSame(dayjs(), "year");

  const startDateIsThisWeek =
    dayjs.unix(event.startDate).isSame(dayjs(), "week") &&
    dayjs.unix(event.startDate).isSame(dayjs(), "year");

  return (
    <StyledEventCard onClick={onExpand}>
      <CardContainer>
        <ContentWrapper isExpanded={isExpanded}>
          <ImageSection>
            <CardImage>
              <img
                src={
                  !!event.thumbnail
                    ? event.thumbnail
                    : "/assets/images/event/placeholder.png"
                }
                alt={event.title}
                className="w-100"
                height={300}
                width={300}
              />
            </CardImage>
          </ImageSection>

          <CardBody>
            <DateTile
              isToday={startDateIsToday}
              isThisWeek={startDateIsThisWeek}
            >
              <h4 className="fw-700 font-md ls-3 mb-0">
                <span className="ls-3 d-block font-xsss text-white fw-500">
                  {dayjs.unix(event.startDate).format("DD")}
                </span>
                <span className="text-uppercase">
                  {dayjs.unix(event.startDate).format("MMM")}
                </span>
                <span className="ls-3 d-block font-xsss text-white fw-500">
                  {dayjs.unix(event.startDate).format("YYYY")}
                </span>
              </h4>
            </DateTile>

            <EventContent>
              <h2 className="fw-700 lh-3 font-xss">{event.title}</h2>
              <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                <i className="ti-location-pin me-1"></i>
                {event.location}
              </span>
              <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                <i className="ti-time me-1"></i>
                {dayjs.unix(event.startDate).format("HH:mm")}
                {` ${t("until").toLowerCase()} `}
                {startAndEndDateAreEqual
                  ? dayjs.unix(event.endDate).format("HH:mm")
                  : dayjs.unix(event.endDate).format("DD MMM HH:mm")}
              </span>
            </EventContent>
          </CardBody>
          <Description>{event.description}</Description>
        </ContentWrapper>
      </CardContainer>
    </StyledEventCard>
  );
};

const StyledEventCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  background-color: white;
  padding: 1.5rem;
  border-radius: ${styles.card_border_radius};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
`;

const CardContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 405px;
`;

const ContentWrapper = styled.div`
  position: relative;
  transition: transform 0.3s ease-in-out;
  transform: translateY(${(props) => (props.isExpanded ? "-300px" : "0")});
`;

const ImageSection = styled.div`
  position: relative;
  height: 300px;
`;

const CardImage = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 300px;
  border-radius: ${styles.card_border_radius};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-out;
    user-drag: none;
    -webkit-user-drag: none;
  }

  &:hover img {
    transform: scale(105%);
  }
`;

const CardBody = styled.div`
  display: flex;
  gap: 1rem;
  padding-top: 1.5rem;
`;

const DateTile = styled.div`
  height: fit-content;
  width: fit-content;
  padding: 0.5rem;
  background-color: ${({ isToday, isThisWeek }) =>
    isToday
      ? colors.secondary
      : isThisWeek
        ? colors.secondaryLight
        : colors.primaryLight};
  border-radius: ${styles.btn_border_radius};

  h4:last-child {
    color: ${({ isToday }) => (isToday ? "white" : "initial")};
  }
`;

const EventContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  margin-top: 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default EventCard;
