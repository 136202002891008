import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { device } from "../utils/breakpoints";
import { colors, styles } from "../utils/theme";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    rgba(245, 237, 224, 1) 20%,
    rgba(98, 144, 111, 1) 100%
  );
  overflow: hidden;
  min-height: 100vh;
  position: relative;
`;

const DesktopHeader = styled.header`
  width: 100%;
  padding: 20px 0;
  z-index: 9;

  display: none;
  @media ${device.lg} {
    display: block;
  }
`;

const MobileItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
`;

const HamburgerMenu = styled.div`
  display: block;
  position: relative;
  z-index: 1;

  -webkit-user-select: none;
  user-select: none;

  span {
    display: block;
    width: 28px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;

    background: ${colors.hamburger};
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  &.active {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(1px, -3px);
      background: ${colors.hamburger};
    }

    span:nth-last-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    span:nth-last-child(1) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
`;

const MobileHeader = styled.div`
  display: block;
  padding-top: 0.4rem;
  @media ${device.lg} {
    display: none;
  }
`;

const NavItem = styled.li`
  a {
    color: ${colors.secondary};
  }
`;

const StyledText = styled(ReactMarkdown)`
  font-size: 16px;
  line-height: 24px;

  @media ${device.lg} {
    font-size: 20px;
    line-height: 30px;
  }
`;

const BannerLead = styled.p`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 0.5rem;

  @media ${device.lg} {
    font-size: 22px;
    line-height: 32px;
  }
`;

const BannerDescription = styled.p`
  font-size: 16px;
  line-height: 24px;

  @media ${device.lg} {
    font-size: 20px;
    line-height: 30px;
  }
`;

const BannerTitle = styled.h2`
  font-size: 26px;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: ${colors.header};
  text-transform: uppercase;

  @media ${device.lg} {
    font-size: 34px;
  }
`;

const BannerImage = styled.img`
  max-width: 150px;
`;

const Image = styled.img`
  width: 100%;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
`;

const StyledCard = styled.div`
  height: 100%;
  text-align: left;
  background-color: ${colors.light};
  border-radius: ${styles.card_border_radius};
  margin-bottom: 1rem;
  overflow: hidden;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 10%);

  @media ${device.lg} {
    margin-bottom: 0;
  }

  @media ${device.xl} {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 10%);
  }
`;

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${colors.header};
  @media ${device.lg} {
    font-size: 38px;
  }
`;

const HeaderSubtitle = styled.h4`
  font-size: 22px;
  font-weight: 300;

  @media ${device.lg} {
    font-size: 24px;
  }
`;

export {
  PageWrapper,
  DesktopHeader,
  MobileHeader,
  MobileItemsWrapper,
  HamburgerMenu,
  NavItem,
  StyledText,
  BannerLead,
  BannerDescription,
  BannerTitle,
  BannerImage,
  Image,
  StyledCard,
  HeaderTitle,
  HeaderSubtitle,
};
