import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  about,
  contact,
  dashboardHome,
  home,
  productDetail,
  publicEvents,
} from "../urls";
import { NavLink } from "react-router-dom";
import { colors } from "../utils/theme";
import { toggleHireSpaceModal } from "../redux/actions/modalActions";
import StyledButton from "./StyledButton";
import { useDispatch } from "react-redux";
import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";

const MobileMenuHome = ({ active, handleMenu }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [productsOpen, setProductsOpen] = useState(false);

  const toggleProducts = (e) => {
    e.preventDefault();
    setProductsOpen(!productsOpen);
  };

  return (
    <Wrapper className={active ? "active" : ""}>
      <CloseButton onClick={handleMenu}>
        <CloseOutlined />
      </CloseButton>
      <Content>
        <StyledNav>
          <ul>
            <li>
              <MenuItem to={home} activeClassName="active" onClick={handleMenu}>
                {t("oostwest")}
              </MenuItem>
            </li>
            <li>
              <MenuItem
                to={about}
                activeClassName="active"
                onClick={handleMenu}
              >
                {t("community")}
              </MenuItem>
            </li>
            <li>
              <ProductMenuItem onClick={toggleProducts}>
                {t("spaces_at_oostwest")}
                {productsOpen ? (
                  <UpOutlined
                    style={{ marginLeft: "8px", width: "12px", heigt: "auto" }}
                  />
                ) : (
                  <DownOutlined
                    style={{ marginLeft: "8px", width: "12px", heigt: "auto" }}
                  />
                )}
              </ProductMenuItem>
              <ProductsDropdown className={productsOpen ? "open" : ""}>
                <ProductLink
                  to={productDetail.replace(":productId", "flexwerken")}
                  onClick={handleMenu}
                  activeClassName="active"
                >
                  {t("flex_working")}
                </ProductLink>
                <ProductLink
                  to={productDetail.replace(":productId", "vaste-unit")}
                  onClick={handleMenu}
                  activeClassName="active"
                >
                  {t("fixed_unit")}
                </ProductLink>
                <ProductLink
                  to={productDetail.replace(
                    ":productId",
                    "multifunctionele-ruimte"
                  )}
                  onClick={handleMenu}
                  activeClassName="active"
                >
                  {t("multipurpose_space")}
                </ProductLink>
              </ProductsDropdown>
            </li>
            <li>
              <MenuItem
                to={publicEvents}
                activeClassName="active"
                onClick={handleMenu}
              >
                {t("events")}
              </MenuItem>
            </li>
            <li>
              <MenuItem
                to={contact}
                activeClassName="active"
                onClick={handleMenu}
              >
                {t("contact")}
              </MenuItem>
            </li>
          </ul>
          <Divider />
          <MenuItem
            to={dashboardHome}
            activeClassName="active"
            onClick={handleMenu}
          >
            {t("login")}
          </MenuItem>
          <RentSpaceButton
            onClick={() => {
              dispatch(toggleHireSpaceModal());
              handleMenu();
            }}
          >
            {t("rent_a_space")}
          </RentSpaceButton>
        </StyledNav>
        <StyleElement>
          <img
            src={"assets/images/illustrations/illustratie-07.svg"}
            alt={""}
          />
        </StyleElement>
      </Content>
    </Wrapper>
  );
};

const CloseButton = styled.div`
  width: 100%;
  padding-right: 0.5rem;
  padding-top: 0.5rem;

  .anticon {
    float: right;
    font-size: 1.75rem;
    color: #888;
  }
`;

const RentSpaceButton = styled(StyledButton)`
  margin-top: 1rem;
`;

const MenuItem = styled(NavLink)`
  display: block;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.5rem 0;
  color: ${colors.menu_items};
`;

const StyledNav = styled.nav`
  padding: 0 1rem 1rem 2rem;

  li a {
    position: relative;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: ${colors.secondary};
    }
  }
`;

const StyleElement = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  img {
    max-width: 50vw;
    opacity: 0.6;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 0;
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(245, 237, 224, 1) 20%,
    rgba(98, 144, 111, 1) 100%
  );
  /* to stop flickering of text in safari */
  -webkit-font-smoothing: antialiased;
  transform: translate(-100%, 0);
  transition: transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1);

  &.active {
    transform: translate(0, 0);
  }
`;

const ProductMenuItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.5rem 0;
  color: ${colors.menu_items};
  cursor: pointer;
`;

const ProductsDropdown = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding-left: 1rem;

  &.open {
    max-height: 200px; // Adjust this value based on your content
  }
`;

const ProductLink = styled(NavLink)`
  display: block;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.5rem 0;
  color: ${colors.menu_items};
`;

export default MobileMenuHome;
